<template>
  <div>
    <welcome />
  </div>
</template>

<script>
  export default {
    name: 'CoreView',

    components: {
      Welcome: () => import('@/components/Welcome'),
    },
  }
</script>
